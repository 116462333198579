import React, { useState } from 'react';
import { HttpFetcher } from './HttpFetcher';
import { PageHeader } from './PageHeader';
import './Markets.css';

export function Markets(props) {

    const [dtos, setDtos] = useState(null);
    const [showNewMarket, setShowNewMarket] = useState(false);
    const [newMarketName, setNewMarketName] = useState("");
    const [createMarket, setCreateMarket] = useState(false);

    const handleGetAllResponse = (response) => {
        setDtos(response);
    };

    const handleMarketNameChange = (e) => {
        setNewMarketName(e.target.value);
    }

    const handleMarketCreate = () => {
        if (newMarketName) {
            setCreateMarket(true);
        }
    }

    var fetcher = null;

    if (!dtos) {
        fetcher =
            <>
                <div>Loading...</div>
                <HttpFetcher params={{ url: 'api/market', handleResponse: (json) => handleGetAllResponse(json) }} />
            </>;
    } else if (createMarket) {
        fetcher =
            <>
                <div>Creating market...</div>
                <HttpFetcher params={{
                    url: 'api/market',
                    options: {
                        method: 'POST',
                        body: JSON.stringify({ marketName: newMarketName })
                    },
                    handleResponse: (json) => {
                        setDtos([...dtos, { marketId: json, marketName: newMarketName }]);
                        setNewMarketName("");
                        setCreateMarket(false);
                        setShowNewMarket(false);
                    }
                }} />
            </>;
    }

    var createMarketUI = null;

    if (showNewMarket) {
        createMarketUI =
            <div className="campaigns-new container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <input type="text" onChange={handleMarketNameChange} placeholder="New market name..." className={`form-control ${!newMarketName ? 'invalid' : ''}`}></input>
                    </div>
                    <div className="col-md-2">
                        <button onClick={handleMarketCreate} className="form-control">
                            Create
                        </button>
                    </div>
                </div>
            </div>;
    }

    var contents = null;

    if (dtos) {
        if (dtos.length === 0) {
            contents = <div>no markets</div>;
        } else {
            contents = <table className='markets table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Market Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {dtos.map(dto =>
                        <tr key={dto.marketId}>
                            <td>{dto.marketName}</td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
        }
    }

    return (
        <div>
            <PageHeader>
                <span className="primary">
                    Markets
                </span>

                <div className="secondary">
                    <button onClick={() => setShowNewMarket(!showNewMarket)}>
                        New Market
                    </button>
                </div>
            </PageHeader>

            {fetcher}
            {createMarketUI}
            {contents}
        </div>
    );
}