import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, } from '@fortawesome/free-solid-svg-icons'
import './Icons.css'

export function EditIcon() {
    return (
        <span className="icon">
            <FontAwesomeIcon icon={faPencil} />
        </span>
    )
}