import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { HttpFetcher } from './HttpFetcher';
import { PageHeader } from './PageHeader';
import { BottomBar } from './BottomBar';
import { Storage } from "./Storage";
import { TrashIcon } from "./TrashIcon";
import { TemplateEntity } from '../domain/TemplateEntity';
import { TemplateStageModel } from '../domain/TemplateStageModel'
import { TemplateStageFieldModel } from '../domain/TemplateStageFieldModel'
import './Template.css';

export function Template(props) {

    const [templateEntity, setTemplateEntity] = useState(null);
    const [templateModel, setTemplateModel] = useState(null);

    const handleGetTemplate = (dtoTemplate) => {
        setTemplateEntity({});
        var templateEntity = new TemplateEntity(dtoTemplate);
        setTemplateEntity(templateEntity);
        var model = templateEntity.getNewModel();
        setTemplateModel(model);
    };

    let { templateId } = useParams();
    templateId = parseInt(templateId);

    if (templateId === 0 && !templateModel) {
        handleGetTemplate({ templateName: '', templateStages: [{ orderId: 0, templateStageName: '', templateStageFields: []}] });
    }
    
    const [templateHeader] = useState(Storage.getTemplateHeader(templateId));
    const [saveTemplate, setSaveTemplate] = useState(false);
    
    const [reRender, setReRender] = useState(false);
    const [fields, setFields] = useState(null);
    const [goingBackAfterSave, setGoingBackAfterSave] = useState(false);
    const [goBack, setGoBack] = useState(false);

    const handleGetFieldsResponse = (response) => {
        Storage.setFields(response);
        setFields(response);
    };

    const handleFieldOrderIdChange = (e, ts, tsf) => {
        var newOrderId = parseInt(e.currentTarget.value);
        ts.updateFieldOrderId(tsf, newOrderId);
        e.currentTarget.value = -1;
        setReRender(true);
    }

    const handleFieldAdd = (e, ts) => {
        var fieldId = parseInt(e.currentTarget.value);
        if (fieldId > -1) {
            var field = Storage.getField(fieldId);
            var model = new TemplateStageFieldModel(field.fieldId, ts.templateStageFields.length);
            ts.addField(model);
            e.currentTarget.value = "-1";
            setReRender(true);
        }
    }

    const handleFieldRemove = (ts, tsf) => {
        ts.removeField(tsf);
        setReRender(true);
    }

    const handleNewStageNameKeyDown = (e) => {
        if (e.keyCode === 13) {
            var model = new TemplateStageModel(templateModel.templateStages.length, e.currentTarget.value, []);
            templateModel.addTemplateStage(model);
            e.currentTarget.value = null;
            setReRender(true);
        }
    }

    const handleDeleteStage = (ts) => {
        templateModel.removeTemplateStage(ts);
        setReRender(true);
    }

    const handleTemplateStageNameChange = (e, ts) => {
        ts.templateStageName = e.target.value;
        if (ts.isTemplateStageNameValid()) {
            e.target.className = 'form-control';
        } else {
            e.target.className = 'form-control invalid';
        }
        setReRender(true);
    }

    const handleTemplateNameChange = (e) => {
        templateModel.templateName = e.target.value;
        if (templateModel.isTemplateNameValid()) {
            e.target.className = 'form-control';
        } else {
            e.target.className = 'form-control invalid';
        }
        setReRender(true);
    }

    const handleTemplateSave = () => {
        setSaveTemplate(true);
    }

    const handleTemplateSaveAndClose = () => {
        handleTemplateSave();
        setGoingBackAfterSave(true);
    }

    const handleReset = () => {
        setTemplateModel(templateEntity.getNewModel());
        setReRender(true);
    }

    const handleGoBack = () => {
        setGoBack(true);
    }

    const getSavePayload = () => {
        var dto = templateModel.toDto();
        var json = JSON.stringify(dto);

        return json;
    }

    if (!fields) {
        return (
            <>
                <div>Loading fields...</div>
                <HttpFetcher params={{ url: 'api/field', handleResponse: (json) => handleGetFieldsResponse(json) }} />
            </>
        );
    }

    if (templateId > 0 && templateEntity == null) {
        return (
            <>
                <div>Loading template...</div>
                <HttpFetcher params={{ url: 'api/template/' + templateId, handleResponse: (response) => handleGetTemplate(response) }} />
            </>
        );
    }

    if (saveTemplate) {
        return (
            <>
                <div>Saving template...</div>
                <HttpFetcher params={{
                    url: `api/template/${templateId === 0 ? '' : templateId}`,
                    options: {
                        method: templateId === 0 ? 'POST' : 'PUT',
                        body: getSavePayload()
                    },
                    handleResponse: (json) => {
                        setSaveTemplate(false);
                        if (templateId === 0) {
                            var id = JSON.parse(json);
                            Storage.setTemplateHeader({ templateId: id, templateName: templateModel.templateName, campaignCount: 0 });
                            setGoBack(true);
                        } else {
                            templateEntity.update(templateModel);
                            setTemplateEntity(templateEntity);
                            setTemplateModel(templateEntity.getNewModel());
                            Storage.setTemplateHeader(templateEntity);
                            if (goingBackAfterSave) {
                                setGoingBackAfterSave(false);
                                setGoBack(true);
                            }
                        }
                    }
                }}
                />
            </>
        );
    }

    if (goBack) {
        return (<Navigate to="/templates"></Navigate>);
    }

    var content = null;

    if (templateModel || reRender) {

        if (reRender)
            setReRender(false);

        content =
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="template box-shadow">
                            <div className="template-name">
                                <input type="text" value={templateModel.templateName} onChange={(e) => handleTemplateNameChange(e)} placeholder="Template name required" className={`form-control ${!templateModel.isTemplateNameValid() ? 'invalid' : '' }`} />
                            </div>
                            {templateModel.templateStages.map(ts =>
                                <div key={ts.orderId} className="template-stage">
                                    <div className="template-stage-name">
                                        <div className="template-stage-name-input">
                                            <input type="text" value={ts.templateStageName} onChange={(e) => handleTemplateStageNameChange(e, ts)} placeholder="Stage name required" className={`form-control ${!ts.isTemplateStageNameValid() ? 'invalid' : ''}`} />
                                        </div>
                                        <div className="template-stage-name-icons">
                                            <span onClick={() => handleDeleteStage(ts)}>
                                                <TrashIcon />
                                            </span>
                                        </div>
                                    </div>
                                    {ts.templateStageFields.map(tsf => {
                                        var orderIdOption = 0;
                                        var fieldName = Storage.getFieldName(tsf.fieldId);
                                        return <div key={`${tsf.fieldId}_${tsf.orderId}`} className="template-stage-field">
                                            <select onChange={(e) => handleFieldOrderIdChange(e, ts, tsf)} value={tsf.orderId}>
                                                {ts.templateStageFields.map(field =>
                                                    <option key={field.fieldId} value={orderIdOption}>
                                                        {++orderIdOption}
                                                    </option>)}
                                            </select>
                                            <span onClick={() => handleFieldRemove(ts, tsf)}><TrashIcon /></span>
                                            <span className="template-stage-field-name">{fieldName}</span>
                                        </div>
                                    })}

                                    {ts.templateStageFields.length === 0 &&
                                        <div className="template-stage-field-invalid">
                                            At least one field is required
                                        </div>
                                    }

                                    <div className="template-stage-field-add">
                                        Add field
                                        <select onChange={(e) => handleFieldAdd(e, ts)}>
                                            <option defaultValue value="-1">--Select--</option>
                                            {fields.filter(field => !ts.hasField(field.fieldId)).map(field =>
                                                <option key={field.fieldId} value={field.fieldId}>
                                                    {field.fieldName}
                                                </option>
                                            )}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="template-stage-add">
                                <input type="text" placeholder="New stage name..." onKeyDown={(e) => handleNewStageNameKeyDown(e)} className="form-control"></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>;
    }    

    return (
        <>
            <PageHeader>
                <span className="primary">Templates</span>
                <span className="secondary">{templateHeader ? templateHeader.templateName : "New"}</span>
            </PageHeader>

            {content}

            <BottomBar>
                {templateId !== 0
                    ? <button onClick={handleTemplateSave} disabled={!templateModel || !templateModel.isValid()}>
                        Save
                    </button>
                    : <></>}
                <button onClick={handleTemplateSaveAndClose} disabled={!templateModel || !templateModel.isValid()}>
                    Save & Close
                </button>
                <button onClick={() => handleReset()}>
                    Reset
                </button>
                <button onClick={() => handleGoBack()}>
                    Cancel
                </button>
            </BottomBar>
        </>
    );
}