import React from 'react';
import { CheckOption } from './CheckOption'
import './InitialContact.css';

export function InitialContact(props) {

    var isSuccess = props.params.initialValue === true;
    var isFailure = props.params.initialValue === false;
    var isContacted = props.params.isContacted;

    const onSuccess = (value) => {
        var result = value === true ? true : null
        props.params.onSelect(result, 1);
    }

    const onFailure = (value) => {
        var result = value === true ? false : null;
        props.params.onSelect(result, 0);
    }

    var rowClass = "initial-contact-option";

    if (isSuccess)
        rowClass += "-success";
    else if (isFailure)
        rowClass += "-failed";
    else if (isContacted) {
        rowClass += "-disabled";
    }

    var successOptionClass = "success-option";
    if ((isContacted && !isSuccess) || isFailure)
        successOptionClass += "-disabled";
    else if (isSuccess) {
        successOptionClass += "-selected";
    }

    var failOptionClass = "fail-option";
    if ((isContacted && !isFailure) || isSuccess)
        failOptionClass += "-disabled";
    else if (isFailure) {
        failOptionClass += "-selected";
    }

    return (
        <div className={rowClass}>
            <div className="initial-contact-option-cell">
                {props.params.label}
            </div>
            <div className="initial-contact-option-cell">
                <CheckOption params={{ label: 'Success', initialValue: isSuccess, onSelect: (value) => onSuccess(value), className: successOptionClass }} />
            </div>
            <div className="initial-contact-option-cell">
                <CheckOption params={{ label: 'Failed', initialValue: isFailure, onSelect: (value) => onFailure(value), className: failOptionClass }} />
            </div>
        </div>
    )
}