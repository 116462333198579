import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons'
import './CheckOption.css';

export function CheckOption(props) {

    const isChecked = props.params.initialValue || false;

    const label = props.params.label;

    const onToggle = () => {
        props.params.onSelect(!isChecked);
    }

    var icon = faSquare;
    if (isChecked) {
        icon = faCheckSquare;
    }

    return (
        <span onClick={() => onToggle()} className="check-option">
            <span className={props.params.className}>
                <span className="check-option-icon"><FontAwesomeIcon icon={icon} /></span>
                <span className="check-option-label">{label}</span>
            </span>
        </span>
    )
}