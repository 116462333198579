import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, } from '@fortawesome/free-solid-svg-icons'
import './Icons.css'

export function TrashIcon() {
    return (
        <span className="icon">
            <FontAwesomeIcon icon={faTrash} />
        </span>
    )
}