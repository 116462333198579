import { TemplateModel } from "./TemplateModel";
import { TemplateStageFieldModel } from "./TemplateStageFieldModel";
import { TemplateStageModel } from "./TemplateStageModel";

export class TemplateEntity {
    #templateId;
    #model;
    #dtoStages;

    constructor(dto) {
        this.#templateId = dto.templateId;

        this.#dtoStages = dto.templateStages;

        this.#model = new TemplateModel(
            dto.templateName,
            dto.templateStages);
    }

    get templateId() {
        return this.#templateId;
    }

    get templateName() {
        return this.#model.templateName;
    }

    set templateName(templateName) {
        if (!templateName)
            throw new Error("Invalid template name");

        this.#model.templateName = templateName;
    }

    get model() {
        return this.#model;
    }

    getNewModel() {
        var model = new TemplateModel(
            this.model.templateName,
            this.#dtoStages);

        return model;
    }

    update(model) {
        this.#dtoStages = model.templateStages;
        this.#model = model;
    }
}