import { ManualLeadEntry } from './ManualLeadEntry'

export class ManualLeadEntries {
    #entries;
    #entriesByFieldId;

    constructor() {
        this.#entries = [];
        this.#entriesByFieldId = {};
    }

    saveEntry(fieldId, value) {
        var entry = new ManualLeadEntry(fieldId, value);

        var key = `field_${fieldId}`;

        if (!this.#entriesByFieldId[key]) {
            this.#entries.push(entry);
        } else {
            var index = this.#entries.findIndex(e => e.fieldId === fieldId);
            this.#entries[index] = entry;
        }

        this.#entriesByFieldId[key] = entry;
    }

    toDtos() {
        return this.#entries.map(e => e.toDto());
    }
}