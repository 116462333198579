import React, { useState } from 'react';
import { HttpFetcher } from './HttpFetcher';
import { PageHeader } from './PageHeader';
import './Domains.css';

export function Domains(props) {

    const [dtos, setDtos] = useState(null);
    const [showNewDomain, setShowNewDomain] = useState(false);
    const [newDomainAddress, setNewDomainAddress] = useState("");
    const [createDomain, setCreateDomain] = useState(false);

    const handleGetAllResponse = (response) => {
        setDtos(response);
    };

    const handleDomainAddressChange = (e) => {
        setNewDomainAddress(e.target.value);
    }

    const handleDomainCreate = () => {
        if (newDomainAddress) {
            setCreateDomain(true);
        }
    }

    var fetcher = null;

    if (!dtos) {
        fetcher =
            <>
                <div>Loading...</div>
                <HttpFetcher params={{ url: 'api/domain', handleResponse: (json) => handleGetAllResponse(json) }} />
            </>;
    } else if (createDomain) {
        fetcher =
            <>
                <div>Creating domain...</div>
                <HttpFetcher params={{
                    url: 'api/domain',
                    options: {
                        method: 'POST',
                        body: JSON.stringify({ domainAddress: newDomainAddress })
                    },
                    handleResponse: (json) => {
                        setDtos([...dtos, { domainId: json, domainAddress: newDomainAddress }]);
                        setNewDomainAddress("");
                        setCreateDomain(false);
                        setShowNewDomain(false);
                    }
                }} />
            </>;
    }

    var createDomainUI = null;

    if (showNewDomain) {
        createDomainUI =
            <div className="campaigns-new container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <input type="text" onChange={handleDomainAddressChange} placeholder="New domain name..." className={`form-control ${!newDomainAddress ? 'invalid' : ''}`}></input>
                    </div>
                    <div className="col-md-2">
                        <button onClick={handleDomainCreate} className="form-control">
                            Create
                        </button>
                    </div>
                </div>
            </div>;
    }

    var contents = null;

    if (dtos) {
        if (dtos.length === 0) {
            contents = <div>no domains</div>;
        } else {
            contents = <table className='domains table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Domain Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {dtos.map(dto =>
                        <tr key={dto.domainId}>
                            <td>{dto.domainAddress}</td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
        }
    }

    return (
        <div>
            <PageHeader>
                <span className="primary">
                    Domains
                </span>

                <div className="secondary">
                    <button onClick={() => setShowNewDomain(!showNewDomain)}>
                        New Domain
                    </button>
                </div>
            </PageHeader>

            {fetcher}
            {createDomainUI}
            {contents}
        </div>
    );
}