import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { LoginModal } from './LoginModal';

export function HttpFetcher(props) {

    const [navigateToLogin, setNavigateToLogin] = useState(null);
    const [openLoginModal, setOpenLoginModal] = useState(false);

    const getRequestOptions = (options) => {
        var requestOptions = options || {
            'headers': {}
        };

        if (!requestOptions.headers) {
            requestOptions = { ...requestOptions, 'headers': {} };
        }

        requestOptions.headers['Content-Type'] = 'application/json';
        requestOptions.headers['react_client'] = 'true';
        return requestOptions;
    }

    const executeFetch = () => {
        console.log("executeFetch");

        var requestOptions = getRequestOptions(props.params.options);

        fetch(props.params.url, requestOptions)
            .then(response => {
                if (response.status === 401) {
                    executeReAuth();
                } else {
                    response.text()
                        .then(text => {
                            if (text === "")
                                props.params.handleResponse(null);
                            else
                                props.params.handleResponse(JSON.parse(text));
                        })
                }
            });
    };

    const executeReAuth = () => {
        console.log("executeReAuth");

        var requestOptions = getRequestOptions();
        fetch('api/auth/silent', requestOptions)
            .then(response => {
                if (response.status === 401) {
                    setOpenLoginModal(true);
                }
                else {
                    executeFetch();
                }
            });
    }

    const onModalClose = (isLoggedIn) => {
        if (!isLoggedIn) {
            setNavigateToLogin(true);
        } else {
            executeFetch();
        }
    }

    if (navigateToLogin === true) {
        return <Navigate to="/" />
    } else if (openLoginModal) {
        return <LoginModal onModalClose={(isLoggedIn) => onModalClose(isLoggedIn)} />
    } else {
        executeFetch();

        return (
            <></>
        );
    }
}