import React, { useState } from 'react';
import { InitialContact } from './InitialContact'
import { Stages } from './Stages'
import { SelectDropDown } from './SelectDropDown'
import './InitialContacts.css';
import { HttpFetcher } from './HttpFetcher';

export function InitialContacts(props) {

    var campaignLeadId = props.params.campaignLeadId;
    var dto = props.params.initialContact;

    const [updateContacts, setUpdateContacts] = useState(false);
    const [isContacted, setIsContacted] = useState(dto.isContacted);
    const [contactedMorning, setContactedMorning] = useState(dto.contactedMorning);
    const [contactedAfternoon, setContactedAfternoon] = useState(dto.contactedAfternoon);
    const [contactedEvening, setContactedEvening] = useState(dto.contactedEvening);
    const [contactedWeekend, setContactedWeekend] = useState(dto.contactedWeekend);

    const [updateStage, setUpdateStage] = useState(false);
    const [stageId, setStageId] = useState(props.params.stageId);

    const handleStageUpdateResponse = (json) => {
        console.log("handleStageUpdateResponse complete");
        setUpdateStage(false);
    }

    const handleInitialContactUpdateResponse = (json) => {
        console.log("handleInitialContactUpdateResponse complete");
        setUpdateContacts(false);
    }

    const filterStages = () => {
        var stages = Stages.getAll();

        var result = [];

        var hasAtLeastOneAttempt = isContacted || (contactedMorning === false || contactedAfternoon === false || contactedEvening === false || contactedWeekend === false);
        var hasAllAttempts = (contactedMorning === false && contactedAfternoon === false && contactedEvening === false && contactedWeekend === false);

        stages.forEach(stage => {
            if (stage.stageId == stageId) {
                result.push({ value: stage.stageId, name: stage.name });
            } else if (!hasAtLeastOneAttempt) {
                if (stage.stageId === 1) {
                    result.push({ value: stage.stageId, name: stage.name });
                }
            } else if (hasAtLeastOneAttempt && !isContacted) {
                if (stage.stageId == 2 || stage.stageId == 99) {
                    result.push({ value: stage.stageId, name: stage.name });
                }
            } else if (isContacted || hasAllAttempts) {
                if (stage.stageId >= 2) {
                    result.push({ value: stage.stageId, name: stage.name });
                }
            }
        });

        return result;
    }

    const onStageChange = (stageId) => {
        setStageId(stageId);
        setUpdateStage(true);
    }

    const getInitialContact = () => {
        var initialContact = {
            contactedMorning: contactedMorning,
            contactedAfternoon: contactedAfternoon,
            contactedEvening: contactedEvening,
            contactedWeekend: contactedWeekend
        };

        return initialContact;
    }

    const selectMorning = (value, id) => {
        if (isContacted) {
            if (contactedMorning === true && id === 1) {
                setContactedMorning(null);
                setIsContacted(false);
                setUpdateContacts(true);
            }
        } else if (contactedMorning === false && id === 0) {
            setContactedMorning(null);
            setUpdateContacts(true);
        } else if (contactedMorning === null) {
            setContactedMorning(value);
            if (value === true)
                setIsContacted(true);
            setUpdateContacts(true);
        }

        console.log('toggled morning (' + value + ')');
    }

    const selectAfternoon = (value, id) => {
        if (isContacted) {
            if (contactedAfternoon === true && id === 1) {
                setContactedAfternoon(null);
                setIsContacted(false);
                setUpdateContacts(true);
            }
        } else if (contactedAfternoon === false && id === 0) {
            setContactedAfternoon(null);
            setUpdateContacts(true);
        } else if (contactedAfternoon === null) {
            setContactedAfternoon(value);
            if (value === true)
                setIsContacted(true);
            setUpdateContacts(true);
        }

        console.log('toggled afternoon (' + value + ')');
    }

    const selectEvening = (value, id) => {
        if (isContacted) {
            if (contactedEvening === true && id === 1) {
                setContactedEvening(null);
                setIsContacted(false);
                setUpdateContacts(true);
            }
        } else if (contactedEvening === false && id === 0) {
            setContactedEvening(null);
            setUpdateContacts(true);
        } else if (contactedEvening === null) {
            setContactedEvening(value);
            if (value === true)
                setIsContacted(true);
            setUpdateContacts(true);
        }
        console.log('toggled evening (' + value + ')');
    }

    const selectWeekend = (value, id) => {
        if (isContacted) {
            if (contactedWeekend === true && id === 1) {
                setContactedWeekend(null);
                setIsContacted(false);
                setUpdateContacts(true);
            }
        } else if (contactedWeekend === false && id === 0) {
            setContactedWeekend(null);
            setUpdateContacts(true);
        } else if (contactedWeekend === null) {
            setContactedWeekend(value);
            if (value === true)
                setIsContacted(true);
            setUpdateContacts(true);
        }

        console.log('toggled weekend (' + value + ')');
    }

    var fetcher = null;

    if (updateStage) {
        fetcher =
            <HttpFetcher params={
                {
                    url: 'api/lead/' + campaignLeadId + '/stage',
                    options: {
                        method: 'PUT',
                        body: JSON.stringify({ stageId: stageId })
                    },
                    handleResponse: (response) => handleStageUpdateResponse(response)
                }
            } />;
    } else if (updateContacts) {
        fetcher =
            <HttpFetcher params={
                {
                    url: 'api/lead/' + campaignLeadId + '/contact',
                    options: {
                        method: 'PUT',
                        body: JSON.stringify(getInitialContact())
                    },
                    handleResponse: (response) => handleInitialContactUpdateResponse(response)
                }
            } />
    }

    return (
        <>
            {fetcher}
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <span className="heading">Initial Contact</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="offset-sm-1 col-sm-11">
                                    <InitialContact params={{ label: 'Morning', initialValue: contactedMorning, isContacted: isContacted, onSelect: (value, id) => selectMorning(value, id) }} />
                                </div>
                                <div className="offset-sm-1 col-sm-11">
                                    <InitialContact params={{ label: 'Afternoon', initialValue: contactedAfternoon, isContacted: isContacted, onSelect: (value, id) => selectAfternoon(value, id) }} />
                                </div>
                                <div className="offset-sm-1 col-sm-11">
                                    <InitialContact params={{ label: 'Evening', initialValue: contactedEvening, isContacted: isContacted, onSelect: (value, id) => selectEvening(value, id) }} />
                                </div>
                                <div className="offset-sm-1 col-sm-11">
                                    <InitialContact params={{ label: 'Weekend', initialValue: contactedWeekend, isContacted: isContacted, onSelect: (value, id) => selectWeekend(value, id) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <span className="heading">Stage</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="offset-sm-1 col-sm-11">
                                    <SelectDropDown params={{ options: filterStages(), selectedValue: stageId, onSelect: (stageId) => onStageChange(stageId) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}