import React, { Component } from 'react';
import './BottomBar.css';

export class BottomBar extends Component {
    static displayName = BottomBar.name;

    render() {
        return (
            <div className="bottom-bar">
                {this.props.children}
            </div>
        );
    }
}