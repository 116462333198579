import React from 'react';
import './SelectDropDown.css';

export function SelectDropDown(props) {

    return (
        <select value={props.params.selectedValue} onChange={(e) => props.params.onSelect(parseInt(e.target.value)) } className="form-control">
            {props.params.options.map(option => 
                <option key={option.value} value={option.value}>{option.name}</option>
            )}
        </select>
    )
}

//<optgroup label="No Good">
//</optgroup>