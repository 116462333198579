import React, { useState, useEffect } from 'react';
//import './Lead.css';
import { Comments } from './Comments'
import { LeadFieldEntries } from './LeadFieldEntries'
import { InitialContacts } from './InitialContacts'
import './Lead.css';

export function Lead(props) {

    var dto = props.params.lead;

    return (
        <div className="container-fluid lead box-shadow">
            <div className="row">
                <div className="col-sm-12 lead-id">
                    LeadId
                </div>
            </div>
            <div className="row">
                <div className="offset-sm-1 col-sm-11 lead-id-value">
                    {dto.campaignLeadId}
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <LeadFieldEntries params={{ entries: dto.entries }} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <InitialContacts params={{ campaignLeadId: dto.campaignLeadId, initialContact: dto.initialContact, stageId: dto.stageId }} />
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 campaign-lead-comments">
                    <Comments params={{ campaignLeadId: dto.campaignLeadId, comments: dto.comments }} />
                </div>
            </div>
        </div>
    )
}