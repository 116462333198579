import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Lead } from './Lead';
import { HttpFetcher } from './HttpFetcher';
import { Storage } from './Storage';
import { Stages } from './Stages';
import { PageHeader } from './PageHeader';
import { BottomBar } from './BottomBar';
import './Campaign.css';

export function Campaign() {
    let { id, stageId } = useParams();
    stageId = parseInt(stageId);

    let campaignName = Storage.getCampaignName(parseInt(id));
    let stageName = Stages.getStageName(stageId);

    const defaultFilter = (previous) => {
        return (dto) => {
            return true;
        };
    }

    const [campaignLeads, setCampaignLeads] = useState(null);
    const [updateStage, setUpdateStage] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filter, setFilter] = useState(defaultFilter);
    const [goBack, setGoBack] = useState(false);

    const handleUpdateStageResponse = (json) => {
        setCampaignLeads(json);
        setUpdateStage(false);
    }

    const handleUncontactedOnlyToggle = () => {
        if (isFiltered) {
            setFilter(defaultFilter);
            setIsFiltered(false);
        } else {
            var newFilter = (previous) => {
                return (dto) => {
                    return !dto.initialContact.isContacted;
                }
            };

            setFilter(newFilter);
            setIsFiltered(true);
        }
    }

    const handleGoBack = () => {
        setGoBack(true);
    }

    var fetcher = null;

    if (!campaignLeads || updateStage) {
        fetcher =
            <>
                <div>Loading...</div>
                <HttpFetcher params={{ url: 'api/lead/' + id + '/' + stageId, handleResponse: (response) => handleUpdateStageResponse(response) }} />
            </>
    }

    if (goBack) {
        return <Navigate to="/" />
    } else if (!campaignLeads) {
        return (
            <>
                {fetcher}
            </>
        );
    } else if (!updateStage && campaignLeads && campaignLeads.length === 0) {
        return (
            <>
                <div>no results</div>
            </>
        );
    } else {
        
        var labelCount = null;
        if (isFiltered)
            labelCount = campaignLeads.length;
        else
            labelCount = campaignLeads.filter(dto => !dto.initialContact.isContacted).length;

        var toggleFilter = <button onClick={() => handleUncontactedOnlyToggle()}>{isFiltered ? "View All" : "View Uncontacted"} ({labelCount})</button>;

        return (
            <>
                {fetcher}
                <PageHeader>
                    <span className="primary">
                        {campaignName}
                    </span>
                    <span className="primary">
                        Stage: {stageName}
                    </span>
                    <span className="secondary">
                        {labelCount > 0 ? toggleFilter : null}
                    </span>
                </PageHeader>

                {campaignLeads.filter(filter).map(dto => 
                    <Lead key={dto.campaignLeadId} params={{ lead: dto }} />
                )}

                <BottomBar>
                    <button onClick={() => handleGoBack()}>
                        Go Back
                    </button>
                </BottomBar>
            </>
        )
    }
}