export class ManualLeadEntry {
    #fieldId;
    #value;

    constructor(fieldId, value) {
        this.#fieldId = fieldId;
        this.#value = value;
    }

    get fieldId() {
        return this.#fieldId;
    }

    get value() {
        return this.#value;
    }

    toDto() {
        return {
            fieldId: this.#fieldId,
            value: this.#value
        };
    }
}