import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import { HttpFetcher } from './HttpFetcher';
import { PageHeader } from './PageHeader';
import { Storage } from "./Storage";
import './Templates.css';

export function Templates(props) {

    const [fields, setFields] = useState(Storage.getFields());
    const [templateHeaders, setTemplateHeaders] = useState(null);
    const [newTemplateName, setNewTemplateName] = useState("");
    const [createTemplate, setCreateTemplate] = useState(false);
    const [currentTemplateId, setCurrentTemplateId] = useState(null);

    const handleGetAllResponse = (response) => {
        setTemplateHeaders(response);
        Storage.setTemplateHeaders(response);
    };

    const handleGetFieldsResponse = (response) => {
        setFields(response);
        Storage.setFields(response);
    }

    if (!fields) {
        return (<HttpFetcher params={{ url: 'api/field', handleResponse: (json) => handleGetFieldsResponse(json) }} />);
    }

    if (!templateHeaders) {
        return (<HttpFetcher params={{ url: 'api/template', handleResponse: (json) => handleGetAllResponse(json) }} />);
    }

    var contents = null;

    if (templateHeaders) {
        if (templateHeaders.length === 0) {
            contents = <div>no templates</div>;
        } else {
            contents = (
                <div className="container-fluid">
                    {templateHeaders.map(dto =>
                        <div key={dto.templateId} className="row" onClick={() => setCurrentTemplateId(dto.templateId)}>
                            <div className="col-md-12">
                                <div className="template box-shadow">
                                    <div className="template-name">{dto.templateName}</div>
                                    <div className="template-stats">{dto.campaignCount} campaign{dto.campaignCount !== 1 ? "s" : ""}</div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            )
        }
    }

    if (createTemplate) {
        return (
            <Navigate to={"/templates/0"} />
        );
    } else if (currentTemplateId !== null) {
        return (
            <Navigate to={"/templates/" + currentTemplateId} />
        );
    } else {
        return (
            <>
                <PageHeader>
                    <span className="primary">Templates</span>

                    <div className="secondary">
                        <button onClick={() => setCreateTemplate(true)}>
                            Create
                        </button>
                    </div>
                </PageHeader>

                {contents}
            </>
        );
    }
}