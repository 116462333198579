import React from 'react';
import './Comment.css';

export function Comment(props) {

    let comment = props.comment;

    var utcDate = comment.createdDateTimeUtc.toString();
    if (utcDate.indexOf('Z') === -1)
        utcDate = utcDate + 'Z';

    return (
        <div className="container-fluid">
            <div className="row lead-comment box-shadow">
                <div className="col-md-12">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="lead-comment-author">{comment.userFirstName} {comment.userLastName}</div>
                            <div className="lead-comment-date">{new Date(utcDate).toLocaleString()}</div>
                            <div className="col-md-12 lead-comment-value">{comment.comment}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}