import React, { useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Storage } from './Storage';
import { HttpFetcher } from './HttpFetcher';
import { PageHeader } from './PageHeader';
import { BottomBar } from './BottomBar';
import { ManualLeadEntries } from '../domain/ManualLeadEntries'
import './LeadManual.css';

export function LeadManual() {

    let { campaignId } = useParams();

    campaignId = parseInt(campaignId);

    const [campaign] = useState(Storage.getCampaign(campaignId));
    const [fields, setFields] = useState(null);
    const [template, setTemplate] = useState(null);
    const [goBack, setGoBack] = useState(false);
    const [saveLead, setSaveLead] = useState(false);
    const [closeAfterSave, setCloseAfterSave] = useState(false);
    const [entries, setEntries] = useState(new ManualLeadEntries());

    const handleFieldEntry = (e, stage, field) => {
        var value = e.target.value;

        entries.saveEntry(field.fieldId, value);

        console.log(value);
    }

    const handleSaveAndNew = () => {
        setSaveLead(true);
        setCloseAfterSave(false);
    }

    const handleSaveAndClose = () => {
        setSaveLead(true);
        setCloseAfterSave(true);
    }

    const handleGoBack = () => {
        setGoBack(true);
    }

    const handleGetFieldsResponse = (response) => {
        Storage.setFields(response);
        setFields(response);
    }

    const handleGetTemplate = (response) => {
        setTemplate(response);
    }

    const getSavePayload = () => {
        var dtos = entries.toDtos();

        return JSON.stringify(dtos);
    }

    const renderFieldEntry = (ts, tsf) => {
        var field = Storage.getField(tsf.fieldId);

        if (field.isMultipleChoice) {
            var fieldIndex = 0;
            return <>
                <select onChange={(e) => handleFieldEntry(e, ts, tsf)} className="form-control">
                    <option defaultValue value="-1">--- Select ---</option>
                    {field.multipleChoiceOptions.map(option =>
                        <option key={fieldIndex++} value={option}>
                            {option}
                        </option>
                    )}
                </select>
            </>
        } else {
            return <input type="text" className="form-control" onChange={(e) => handleFieldEntry(e, ts, tsf)}></input>;
        }
    }

    var render = null;

    if (goBack) {
        render = <Navigate to="/" />
    } else if (!campaign) {
        render = <div>Unknown campaign</div>
    } else if (!fields) {
        render =
            <>
                <div>Loading fields...</div>
                <HttpFetcher params={{ url: 'api/field', handleResponse: (json) => handleGetFieldsResponse(json) }} />
            </>;
    } else if (!template) {
        render =
            <>
                <div>Loading template...</div>
                <HttpFetcher params={{ url: `api/template/${campaign.templateId}`, handleResponse: (response) => handleGetTemplate(response) }} />
            </>
    } else if (saveLead) {
        render =
            <>
                <div>Saving lead...</div>
                <HttpFetcher params={{
                    url: `api/lead/${campaignId}`,
                    options: {
                        method: `POST`,
                        body: getSavePayload()
                    },
                    handleResponse: (json) => {
                        setSaveLead(false);
                        if (closeAfterSave) {
                            handleGoBack();
                        } else {
                            setEntries(new ManualLeadEntries());
                        }
                    }
                }}
                />
            </>;
    } else {
        render = (
            <div className="container-fluid">
                <div className="row">
                    {template.templateStages.map(ts =>
                        <div key={ts.orderId} className="col-md-12">
                            <div className="lead-manual-stage">
                                <div className="lead-manual-stage-name">{ts.templateStageName}</div>
                                {ts.templateStageFields.map(tsf =>
                                    <div key={tsf.orderId} className="lead-manual-stage-field">
                                        <div>
                                            FieldName: {Storage.getField(tsf.fieldId).fieldName}
                                        </div>
                                        <div>
                                            {renderFieldEntry(ts, tsf)}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    return (
        <>
            <PageHeader>
                <span className="primary">{campaign.campaignName}</span>
                <span className="secondary">New Lead</span>
            </PageHeader>

            {render}

            <BottomBar>
                <button onClick={handleSaveAndNew}>
                    Save & New
                </button>
                <button onClick={handleSaveAndClose}>
                    Save & Close
                </button>
                <button onClick={() => handleGoBack()}>
                    Cancel
                </button>
            </BottomBar>
        </>
    )
}