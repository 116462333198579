import React, { useState } from 'react';
import { Comment } from './Comment';
import { Cookies } from './Cookies';
import { HttpFetcher } from './HttpFetcher';
import './Comments.css';

export function Comments(props) {

    const campaignLeadId = props.params.campaignLeadId;
    const [comments, setComments] = useState(props.params.comments || []);
    const [newComment, setNewComment] = useState({ campaignLeadId: null, comment: '' });

    const handleNewComment = (campaignLeadId, e) => {
        if (e.keyCode && e.keyCode === 13) {
            e.preventDefault();
            const comment = e.target.value;

            if (comment && comment.length > 0) {
                setNewComment({ campaignLeadId: campaignLeadId, comment: comment.trim() });
                e.target.value = '';
            }
        }
    };

    const handleNewCommentResponse = (json) => {
        var firstName = Cookies.getValue('fn');
        var lastName = Cookies.getValue('ln');

        setNewComment({ campaignLeadId: null, comment: '' });

        var createdComment = {
            campaignLeadId: newComment.campaignLeadId,
            commentId: json,
            userFirstName: firstName,
            userLastName: lastName,
            comment: newComment.comment,
            createdDateTimeUtc: new Date().toISOString()
        };
        setComments([...comments, createdComment]);
    };

    var fetcher = null;

    if (newComment && newComment.comment) {
        fetcher = <HttpFetcher params={{ url: 'api/lead/comment', options: { method: 'POST', body: JSON.stringify(newComment) }, handleResponse: (response) => handleNewCommentResponse(response) }} />
    }

    return (
        <>
            {fetcher}
            <div className="lead-comments">
                {comments.map(comment =>
                    <Comment key={comment.commentId} comment={comment} />
                )}

                <div className="lead-comment-add">
                    <textarea type="text" placeholder="Add comment..." onKeyDown={(e) => handleNewComment(campaignLeadId, e)}>
                    </textarea>
                </div>
            </div>
        </>
    )
}