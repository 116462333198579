import React, { useState } from 'react';
import { HttpFetcher } from './HttpFetcher';
import { PageHeader } from './PageHeader';
import { Storage } from "./Storage";
import './Fields.css';

export function Fields(props) {

    const [fields, setFields] = useState(null);
    const [showNewField, setShowNewField] = useState(false);
    const [newFieldName, setNewFieldName] = useState("");
    const [createField, setCreateField] = useState(false);

    const handleGetAllResponse = (response) => {
        Storage.setFields(response);
        setFields(response);
    };

    const handleFieldNameChange = (e) => {
        setNewFieldName(e.target.value);
    }

    const handleFieldCreate = () => {
        if (newFieldName) {
            setCreateField(true);
        }
    }

    if (!fields) {
        return (
            <>
                <div>Loading...</div>
                <HttpFetcher params={{ url: 'api/field', handleResponse: (json) => handleGetAllResponse(json) }} />
            </>
        );
    } else if (createField) {
        return (
            <>
                <div>Creating field...</div>
                <HttpFetcher params={{
                    url: 'api/field',
                    options: {
                        method: 'POST',
                        body: JSON.stringify({ fieldName: newFieldName })
                    },
                    handleResponse: (json) => {
                        setFields([...fields, { fieldId: json, fieldName: newFieldName }]);
                        setNewFieldName("");
                        setCreateField(false);
                        setShowNewField(false);
                    }
                }} />
            </>
        );
    }

    var createFieldUI = null;

    if (showNewField) {
        createFieldUI =
            <div className="fields-new container-fluid">
                <div className="row">
                    <div className="col-md-3">
                        <input type="text" onChange={handleFieldNameChange} placeholder="New field name..." className={`form-control ${!newFieldName ? 'invalid' : ''}`}></input>
                    </div>
                    <div className="col-md-2">
                        <button onClick={handleFieldCreate} className="form-control">
                            Create
                        </button>
                    </div>
                </div>
            </div>;
    }

    var contents = null;

    if (fields) {
        if (fields.length === 0) {
            contents = <div>no fields</div>;
        } else {
            contents = <table className='fields table table-striped' aria-labelledby="tabelLabel">
                <thead>
                    <tr>
                        <th>Field Name</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map(dto =>
                        <tr key={dto.fieldId}>
                            <td>{dto.fieldName}</td>
                            <td></td>
                        </tr>
                    )}
                </tbody>
            </table>
        }
    }

    return (
        <div>
            <PageHeader>
                <span className="primary">
                    Fields
                </span>

                <div className="secondary">
                    <button onClick={() => setShowNewField(!showNewField)}>
                        New Field
                    </button>
                </div>
            </PageHeader>

            {createFieldUI}
            {contents}
        </div>
    );
}