//import { Counter } from "./components/Counter";
//import { FetchData } from "./components/FetchData";
//import { Home } from "./components/Home";
//import { Login } from "./components/Login";
import { Campaigns } from "./components/Campaigns";
import { Campaign } from "./components/Campaign";
import { Markets } from "./components/Markets";
import { Fields } from "./components/Fields";
import { Templates } from "./components/Templates";
import { Template } from "./components/Template";
import { LeadManual } from "./components/LeadManual";
import { CampaignEdit } from "./components/CampaignEdit";
import { Domains } from "./components/Domains";

const AppRoutes = [
    //{
    //    index: true,
    //    element: <Login />
    //},
    //{
    //    path: '/counter',
    //    element: <Counter />
    //},
    {
        index: true,
        //path: '/campaigns',
        element: <Campaigns />
    },
    {
        path: '/campaigns/:id/:stageId',
        element: <Campaign />
    },
    {
        path: '/campaigns/:campaignId/manual-lead',
        element: <LeadManual />
    },
    {
        path: '/campaigns/:campaignId/edit',
        element: <CampaignEdit />
    },
    {
        path: '/markets',
        element: <Markets />
    },
    {
        path: '/fields',
        element: <Fields />
    },
    {
        path: '/templates',
        element: <Templates />
    },
    {
        path: '/templates/:templateId',
        element: <Template />
    },
    {
        path: '/domains',
        element: <Domains />
    },
    //{
    //    path: '/fetch-data',
    //    element: <FetchData />
    //}
];

export default AppRoutes;
