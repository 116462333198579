import React, { useEffect, useState } from 'react';
import { LoginModal } from "./LoginModal";

export function LoginStatus() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoggingOut, setIsLoggingOut] = useState(false);
    const [openLoginModal, setOpenLoginModal] = useState(false);

    const handleModalClose = (isLoggedIn) => {
        setOpenLoginModal(false);
        setIsLoggedIn(isLoggedIn);
    }

    useEffect(() => {
        fetch('api/auth/isLoggedIn')
            .then(response => {
                if (response.status >= 400) {
                    setIsLoggedIn(false);
                } else {
                    response.json().then(data => setIsLoggedIn(data));
                }
            });
    }, []);

    useEffect(() => {
        if (isLoggingOut) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: null
            };

            fetch('api/auth/logout', requestOptions)
                .then(response => {
                    setIsLoggingOut(false);
                    setOpenLoginModal(false);
                    setIsLoggedIn(false);
                })
        }
    }, [isLoggingOut]);

    if (openLoginModal) {
        //var props = {
        //    props: { onModalClose: setOpenLoginModal(false) }
        //<Login {... { props: { onModalClose: setOpenLoginModal(false) }}} />
        //};
        return (
            <>
                
                <LoginModal onModalClose={(isLoggedIn) => handleModalClose(isLoggedIn) } />

                <button onClick={() => setOpenLoginModal(true)}>
                    Login
                </button>
            </>
        );
    } else if (!isLoggedIn) {
        return (
            <button onClick={() => setOpenLoginModal(true)}>
                Login
            </button>
        );
    } else {
        return (
            <button onClick={() => setIsLoggingOut(true)}>
                Logout
            </button>
        )
    }
}