import { TemplateStageFieldModel } from "./TemplateStageFieldModel";

export class TemplateStageModel {

    #orderId;
    #templateStageName;
    #templateStageFields;

    constructor(orderId, templateStageName, templateStageFields) {
        this.#orderId = orderId;
        this.#templateStageName = templateStageName;

        this.#templateStageFields = templateStageFields.map((tsf, index) => new TemplateStageFieldModel(tsf.fieldId, index));
    };

    get orderId() {
        return this.#orderId;
    }

    set orderId(orderId) {
        if (orderId < 0)
            throw new Error("orderId '" + orderId + "' cannot be less than 0");

        this.#orderId = orderId;
    }

    get templateStageName() {
        return this.#templateStageName;
    }

    set templateStageName(templateStageName) {
        this.#templateStageName = templateStageName;
    }

    isTemplateStageNameValid() {
        return !!this.#templateStageName;
    }

    isValid() {
        return this.isTemplateStageNameValid() && this.#templateStageFields.length > 0;
    }

    get templateStageFields() {
        return this.#templateStageFields;
    }

    hasField(fieldId) {
        return this.#templateStageFields.filter(field => field.fieldId === fieldId).shift() || false;
    }

    addField(field) {
        this.#templateStageFields.push(field);
        this.#reOrderFields();
    }

    removeField(field) {
        var index = this.templateStageFields.findIndex(f => f.fieldId === field.fieldId && f.orderId === field.orderId);

        if (index > -1) {
            this.#templateStageFields.splice(index, 1);
            this.#reOrderFields();
        } else {
            console.error(`Unable to find field with fieldId ${field.fieldId} and ${field.orderId} for removal`);
        }
    }

    clearFields() {
        this.#templateStageFields = [];
    }

    updateFieldOrderId(field, newOrderId) {
        this.#templateStageFields.forEach(model => {
            if (field.orderId !== model.orderId) {
                if (field.orderId < model.orderId) {
                    if (newOrderId >= model.orderId) {
                        model.orderId -= 1;
                    }
                } else if (field.orderId > model.orderId) {
                    if (newOrderId <= model.orderId) {
                        model.orderId += 1;
                    }
                }
            }
        });

        field.orderId = newOrderId;

        this.#reOrderFields();
    }

    #reOrderFields() {
        this.#templateStageFields = this.#templateStageFields.sort((a, b) => a.orderId - b.orderId);
        this.#templateStageFields.forEach((model, index) => model.orderId = index);
    }

    toDto() {
        return {
            orderId: this.orderId,
            templateStageName: this.templateStageName,
            templateStageFields: this.templateStageFields.map(tsf => tsf.toDto())
        }
    }
}