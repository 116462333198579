export class Stages {
    static getAll = () => {
        var stages = [
            {
                stageId: 1,
                name: "New"
            },
            {
                stageId: 2,
                name: "FUP"
            },
            {
                stageId: 3,
                name: "Appt Scheduled"
            },
            {
                stageId: 4,
                name: "Sat"
            },
            {
                stageId: 5,
                name: "Q Check"
            },
            {
                stageId: 6,
                name: "Closed"
            },
            {
                stageId: 7,
                name: "Permit"
            },
            {
                stageId: 8,
                name: "Installed"
            },
            {
                stageId: 98,
                name: "Dropped"
            },
            {
                stageId: 99,
                name: "Bad Lead"
            }
        ];

        return stages;
    }

    static getStageName = (stageId) => {
        var stages = this.getAll();

        for (var x = 0; x < stages.length; x++) {
            if (stages[x].stageId === stageId) {
                return stages[x].name;
            }
        }

        return null;
    }
}