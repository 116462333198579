export class TemplateStageFieldModel {
    #fieldId;
    #orderId;

    constructor(fieldId, orderId) {
        this.#fieldId = fieldId;
        this.#orderId = orderId;
    }

    get fieldId() {
        return this.#fieldId;
    }

    set fieldId(fieldId) {
        if (fieldId <= 0)
            throw new Error("fieldId '" + fieldId + "' must be greater than 0");

        this.#fieldId = fieldId;
    }

    get orderId() {
        return this.#orderId;
    }

    set orderId(orderId) {
        if (orderId < 0)
            throw new Error("fieldId '" + orderId + "' cannot be negative");

        this.#orderId = orderId;
    }

    toDto() {
        return {
            fieldId: this.fieldId,
            orderId: this.orderId
        };
    }
}