import React from 'react';
import './LeadFieldEntry.css';

export function LeadFieldEntry(props) {

    var entry = props.params.entry;

    return (
        <div key={entry.fieldId} className="lead-field">
            <div className="lead-field-name">{entry.fieldName}</div>
            <div className="lead-field-values">
                {entry.values.map(version =>
                    <div key={version.orderId} className="lead-field-value">
                        <div className="lead-confidence-score">
                            {version.confidenceScore
                                ? <span>Confidence: {version.confidenceScore * 100}%</span>
                                : <span>Manual</span>
                            }
                        </div>
                        <div className="lead-answer">{version.value}</div>
                        <div className="lead-date">{new Date(version.createdDateTimeUtc.toString() + 'Z').toLocaleString()}</div>
                    </div>
                )}
            </div>
        </div>
    )
}