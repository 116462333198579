import React, { useEffect, useState, useCallback } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CheckOption } from './CheckOption';
import './Login.css';

export function LoginModal(props) {

    const [email, setUserEmail] = useState("");
    const [password, setUserPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoggingIn, setIsLoggingIn] = useState(false);
    const [isLoginErrored, setIsLoginErrored] = useState(false);
    const [show, setShow] = useState(true);

    const handleClose = useCallback(() => {
        props.onModalClose(isLoggedIn);
        setShow(false);
    }, [isLoggedIn, props]);

    useEffect(() => {
        if (isLoggedIn) {
            handleClose();
        }
    }, [isLoggedIn, handleClose]);

    useEffect(() => {
        if (isLoggingIn) {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: email, Password: password, RememberMe: rememberMe, ReturnUrl: "" })
            };

            fetch('api/auth/login', requestOptions)
                .then(response => {
                    if (response.status === 200) {
                        setIsLoggingIn(false);
                        setIsLoggedIn(true);
                        setUserEmail("");
                        setUserPassword("");
                        setIsLoginErrored(false);
                    } else {
                        setIsLoggingIn(false);
                        setIsLoggedIn(false);
                        setIsLoginErrored(true);
                    }
                })
        }
    }, [isLoggingIn, email, password, rememberMe, props]);

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Login</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="login-error" style={isLoginErrored ? { display: 'block' } : { display: 'none' }}>
                    <p>Unsuccessful login attempt</p>
                </div>
                <div className="form-group">
                    <label>Email:</label>
                    <input type="text" className="form-control" value={email} onChange={(e) => setUserEmail(e.target.value)} />
                    <label>Password:</label>
                    <input type="password" className="form-control" value={password} onChange={(e) => setUserPassword(e.target.value)} />
                    <CheckOption params={{ label: "Remember Me", initialValue: rememberMe, onSelect: (value) => setRememberMe(value)}} />
                    {/*<input type="checkbox" value={rememberMe ? "on" : "off"} onChange={(e) => setRememberMe(e.target.value === "on" ? true : false)} />*/}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setIsLoggingIn(true)}>
                    Login
                </Button>
            </Modal.Footer>
        </Modal>
    );
}