import React from 'react';
import { LeadFieldEntry } from './LeadFieldEntry'
import './LeadFieldEntries.css';

export function LeadFieldEntries(props) {

    var entries = props.params.entries;

    return (
        <div className="col-sm-12">
            <div className="lead-fields">
                {entries.map(entry =>
                    <LeadFieldEntry key={entry.fieldId} params={{ entry: entry }} />
                )}
            </div>
        </div>
    )
}