export class Storage {
    static #fields;
    static #fieldsByFieldId;

    static setCampaigns = (campaigns) => {
        sessionStorage.setItem('campaigns', JSON.stringify(campaigns));
    }

    static getCampaigns = () => {
        var value = sessionStorage.getItem('campaigns');

        if (value && value !== "")
            return JSON.parse(value);
        else
            return null;
    }

    static getCampaign = (campaignId) => {
        var campaigns = this.getCampaigns();

        if (!campaigns)
            return null;

        for (var x = 0; x < campaigns.length; x++) {
            if (campaigns[x].campaignId === campaignId)
                return campaigns[x];
        }

        return null;
    }

    static getCampaignName = (campaignId) => {
        var campaign = this.getCampaign(campaignId);

        if (campaign)
            return campaign.campaignName;

        return null;
    }

    static setTemplateHeaders = (headers) => {
        sessionStorage.setItem('templates', JSON.stringify(headers));
    }

    static setTemplateHeader = (header) => {
        var headers = this.getTemplateHeaders();
        var headerIndex = headers.findIndex(t => t.templateId === header.templateId);
        if (headerIndex > -1) {
            headers[headerIndex].templateName = header.templateName;
        } else {
            headers.push(header);
        }

        this.setTemplateHeaders(headers);
    }

    static getTemplateHeaders = () => {
        var json = sessionStorage.getItem('templates');
        if (json && json !== "") {
            var headers = JSON.parse(json);
            var sorted = headers.sort(this.dynamicSort("templateName"));
            return sorted;
        } else {
            return null;
        }
    }

    static getTemplateHeader = (templateId) => {
        var templates = this.getTemplateHeaders();

        if (!templates)
            return null;

        for (var x = 0; x < templates.length; x++) {
            if (templates[x].templateId === templateId)
                return templates[x];
        }
    }

    static setFields = (fields) => {
        this.#fields = fields;

        var fieldsByFieldId = {};

        fields.map(f => fieldsByFieldId[`field_${f.fieldId}`] = f);

        this.#fieldsByFieldId = fieldsByFieldId;

        sessionStorage.setItem('fields', JSON.stringify(fields));
    }

    static fieldsExist = () => {
        return this.getFields().length > 0;
    }

    static getFields = () => {
        if (this.#fields) {
            return this.#fields;
        } else {
            var value = sessionStorage.getItem('fields');

            if (value && value !== "")
                return JSON.parse(value);
            else
                return null;
        }
    }

    static getField = (fieldId) => {
        var field = this.#fieldsByFieldId[`field_${fieldId}`];

        return field;
    }

    static getFieldName = (fieldId) => {
        var fields = this.getFields();

        if (!fields)
            return null;

        for (var x = 0; x < fields.length; x++) {
            if (fields[x].fieldId === fieldId)
                return fields[x].fieldName;
        }

        return null;
    }

    static dynamicSort = (property) => {
        var sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            /* next line works with strings and numbers, 
             * and you may want to customize it to your needs
             */
            var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    }
}