import React, { useState, useEffect } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { Storage } from './Storage';
import { HttpFetcher } from './HttpFetcher';
import { PageHeader } from './PageHeader';
import { BottomBar } from './BottomBar';
import { SelectDropDown } from './SelectDropDown';
import './CampaignEdit.css';

export function CampaignEdit() {

    let { campaignId } = useParams();

    campaignId = parseInt(campaignId);

    const [campaign] = useState(Storage.getCampaign(campaignId));
    const [newCampaignName, setNewCampaignName] = useState(campaign.campaignName);
    const [newMarketId, setNewMarketId] = useState(campaign.market.marketId);
    const [newTemplateId, setNewTemplateId] = useState(campaign.templateId);
    const [newDomainId, setNewDomainId] = useState(campaign.domain == null ? -1 : campaign.domain.domainId);
    const [markets, setMarkets] = useState(null);
    const [templates, setTemplates] = useState(Storage.getTemplateHeaders());
    const [domains, setDomains] = useState(null);
    const [goBack, setGoBack] = useState(false);
    const [closeAfterSave, setCloseAfterSave] = useState(false);
    const [saveCampaign, setSaveCampaign] = useState(false);

    const handleSaveAndClose = () => {
        setSaveCampaign(true);
        setCloseAfterSave(true);
    }

    const handleGoBack = () => {
        setGoBack(true);
    }

    const handleGetMarkets = (response) => {
        setMarkets(response);
    }

    const handleGetTemplates = (response) => {
        Storage.setTemplateHeaders(response);
        setTemplates(response);
    }

    const handleGetDomains = (response) => {
        setDomains(response);
    }

    const getSavePayload = () => {
        var dto = {
            campaignName: newCampaignName,
            marketId: newMarketId,
            templateId: newTemplateId,
            domainId: newDomainId < 0 ? null : newDomainId
        };

        return JSON.stringify(dto);
    }

    const onCampaignNameChange = (e) => {
        setNewCampaignName(e.target.value);
    }

    const onMarketChange = (marketId) => {
        setNewMarketId(marketId);
    }

    const onTemplateChange = (templateId) => {
        setNewTemplateId(templateId);
    }

    const onDomainChange = (domainId) => {
        setNewDomainId(domainId);
    }

    const getMarketOption = (m) => {
        return {
            value: m.marketId,
            name: m.marketName
        };
    }

    const getTemplateOption = (t) => {
        return {
            value: t.templateId,
            name: t.templateName
        };
    }

    const getDomainOptions = () => {
        var options = [{ value: -1, name: "<None>" }];

        domains.map(getDomainOption).forEach(d => options.push(d));

        return options;
    }

    const getDomainOption = (d) => {
        return {
            value: d.domainId,
            name: d.domainAddress
        };
    }

    var render = null;

    if (goBack) {
        render = <Navigate to="/" />
    } else if (!campaign) {
        render = <div>Unknown campaign</div>
    } else if (!markets) {
        render =
            <>
                <div>Loading markets...</div>
                <HttpFetcher params={{ url: `api/market/`, handleResponse: (response) => handleGetMarkets(response) }} />
            </>
    } else if (!templates) {
        render =
            <>
                <div>Loading templates...</div>
                <HttpFetcher params={{ url: `api/template/`, handleResponse: (response) => handleGetTemplates(response) }} />
            </>
    } else if (!domains) {
        render =
            <>
                <div>Loading domains...</div>
                <HttpFetcher params={{ url: `api/domain/`, handleResponse: (response) => handleGetDomains(response) }} />
            </>
    } else if (saveCampaign) {
        render =
            <>
                <div>Saving campaign...</div>
                <HttpFetcher params={{
                    url: `api/campaign/${campaignId}`,
                    options: {
                        method: `PUT`,
                        body: getSavePayload()
                    },
                    handleResponse: (json) => {
                        setSaveCampaign(false);
                        if (closeAfterSave) {
                            //Storage.setCampaign({
                            //    campaignId: campaignId,
                            //    campaignName: newCampaignName,
                            //    marketId: newMarketId,
                            //    templateId: newTemplateId,
                            //    domainId: newDomainId
                            //});
                            handleGoBack();
                        }
                    }
                }}
                />
            </>;
    } else {
        render = (
            <div className="container-fluid campaign-edit">
                <div className="row">
                    <div className="col-md-3">
                        Campaign Name
                    </div>
                    <div className="col-md-9">
                        <input type="text" className={`form-control ${!newCampaignName ? 'invalid' : ''}`} value={newCampaignName} onChange={(e) => onCampaignNameChange(e)}></input>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        Market
                    </div>
                    <div className="col-md-9">
                        <SelectDropDown params={{ options: markets.map(getMarketOption), selectedValue: newMarketId, onSelect: (marketId) => onMarketChange(marketId) }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        Template
                    </div>
                    <div className="col-md-9">
                        <SelectDropDown params={{ options: templates.map(getTemplateOption), selectedValue: newTemplateId, onSelect: (templateId) => onTemplateChange(templateId) }} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        Domain
                    </div>
                    <div className="col-md-9">
                        <SelectDropDown params={{ options: getDomainOptions(), selectedValue: newDomainId, onSelect: (domainId) => onDomainChange(domainId) }} />
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <PageHeader>
                <span className="primary">{campaign.campaignName}</span>
                <span className="secondary">Edit</span>
            </PageHeader>

            {render}

            <BottomBar>
                <button onClick={handleSaveAndClose}>
                    Save & Close
                </button>
                <button onClick={() => handleGoBack()}>
                    Cancel
                </button>
            </BottomBar>
        </>
    )
}