import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginStatus } from "./LoginStatus";
import './NavMenu.css';

export function NavMenu() {

    const [isCollapsed, setIsCollapsed] = useState(true);

    function toggleNavbar() {
        setIsCollapsed(!isCollapsed);
    }

    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow" light>
                {/*<NavbarBrand tag={Link} to="/">QQ.Leads.Web</NavbarBrand>*/}
                <NavbarBrand></NavbarBrand>
                <NavbarToggler onClick={toggleNavbar} className="mr-2" />
                <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!isCollapsed} navbar>
                    <ul className="navbar-nav flex-grow">
                        {/*<NavItem>*/}
                        {/*    <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem>*/}
                        {/*    <NavLink tag={Link} className="text-dark" to="/counter">Counter</NavLink>*/}
                        {/*</NavItem>*/}
                        {/*<NavItem>*/}
                        {/*    <NavLink tag={Link} className="text-dark" to="/fetch-data">Fetch data</NavLink>*/}
                        {/*</NavItem>*/}
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/">Campaigns</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/markets">Markets</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/fields">Fields</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/templates">Templates</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={Link} className="text-dark" to="/domains">Domains</NavLink>
                        </NavItem>                        
                        <LoginStatus />
                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
}
