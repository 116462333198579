import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';

export default class App extends Component {
    static displayName = App.name;

    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        return (
            <Layout>
                <Routes>
                    {AppRoutes.map((route, index) => {
                        const { element, ...rest } = route;

                        var div = (
                            <div style={{ paddingTop: "65px" }}>
                                {element}
                            </div>
                        );

                        return <Route key={index} {...rest} element={div} />;
                    })}
                </Routes>
            </Layout>
        );
    }
}
