import { TemplateStageModel } from "./TemplateStageModel";

export class TemplateModel {
    #templateName;
    #templateStages;

    constructor(templateName, templateStages) {
        this.#templateName = templateName;

        if (templateStages && templateStages.length && templateStages.length > 0) {
            this.#templateStages = templateStages.map(ts => new TemplateStageModel(ts.orderId, ts.templateStageName, ts.templateStageFields));
        } else {
            this.#templateStages = [];
        }
    }

    get templateName() {
        return this.#templateName;
    }

    isTemplateNameValid() {
        return !!this.#templateName;
    }

    isValid() {
        var stagesValid = this.#templateStages.length > 0;

        this.#templateStages.map(ts => {
            if (stagesValid === true) {
                stagesValid = ts.isValid();
            }
        });

        return this.isTemplateNameValid() && stagesValid;
    }

    set templateName(templateName) {
        this.#templateName = templateName;
    }

    get templateStages() {
        return this.#templateStages;
    }

    addTemplateStage(templateStage) {
        this.#templateStages.push(templateStage);
        this.reOrderTemplateStages();
    }

    removeTemplateStage(templateStage) {
        var index = this.#templateStages.findIndex(ts => ts.orderId === templateStage.orderId);

        if (index > -1) {
            this.#templateStages.splice(index, 1);
            this.reOrderTemplateStages();
        }
    }

    reOrderTemplateStages() {
        this.#templateStages.sort((a, b) => a.orderId - b.orderId);
        this.#templateStages.forEach((model, index) => model.orderId = index);
    }

    toDto() {
        return {
            templateName: this.templateName,
            templateStages: this.templateStages.map(tsf => tsf.toDto())
        }
    }
}