import React, { Component } from 'react';
import './PageHeader.css';

export class PageHeader extends Component {
    static displayName = PageHeader.name;

    render() {
        return (
            <div className="page-header">
                {this.props.children}
            </div>
        );
    }
}