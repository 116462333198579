import React, { useState } from 'react';
import { Navigate } from "react-router-dom";
import { HttpFetcher } from "./HttpFetcher";
import { Stages } from "./Stages";
import { Storage } from "./Storage";
import { PageHeader } from "./PageHeader";
import { EditIcon } from "./EditIcon";
import './Campaigns.css';

export function Campaigns(props) {

    const [dtos, setDtos] = useState(null);
    const [navigateTo, setNavigateTo] = useState(null);
    const [markets, setMarkets] = useState(null);
    const [templates, setTemplates] = useState(null);
    const [domains, setDomains] = useState(null);
    const [showNewCampaign, setShowNewCampaign] = useState(false);
    const [newCampaignName, setNewCampaignName] = useState('');
    const [newCampaignMarketId, setNewCampaignMarketId] = useState(-1);
    const [newCampaignTemplateId, setNewCampaignTemplateId] = useState(-1);
    const [newCampaignDomainId, setNewCampaignDomainId] = useState(-1);
    const [saveNewCampaign, setSaveNewCampaign] = useState(false);

    const handleNavigateTo = (e, dto, stageId) => {
        e.preventDefault();

        if (dto.leadCount > 0) {
            var to = "/campaigns/" + dto.campaignId.toString() + "/" + stageId.toString();
            setNavigateTo(to);
        }
    }

    const handleGetMarkets = (response) => {
        setMarkets(response);
    }

    const handleGetTemplates = (response) => {
        setTemplates(response);
    }

    const handleGetDomains = (response) => {
        setDomains(response);
    }

    const handleGetCampaigns = (response) => {
        setDtos(response);
        Storage.setCampaigns(response);
    }

    const handleNewCampaignNameChange = (e) => {
        setNewCampaignName(e.target.value);
    }

    const handleNewCampaignDomainChange = (e) => {
        setNewCampaignDomainId(parseInt(e.target.value));
    }

    const handleNewCampaignMarketChange = (e) => {
        setNewCampaignMarketId(parseInt(e.target.value));
    }

    const handleNewCampaignTemplateChange = (e) => {
        setNewCampaignTemplateId(parseInt(e.target.value));
    }

    const handleSaveNewCampaign = () => {
        if (newCampaignName && newCampaignMarketId > -1 && newCampaignTemplateId > -1)
            setSaveNewCampaign(true);
    }

    const handleAddLead = (campaignId) => {
        setNavigateTo(`/campaigns/${campaignId}/manual-lead`);
    }

    const handleCampaignEdit = (campaignId) => {
        setNavigateTo(`/campaigns/${campaignId}/edit`);
    }

    const handleGetNewLeadUrl = (url) => {
        if (url) {
            navigator.clipboard.writeText(url);
            alert("copied new lead url to clipboard!");
        } else {
            alert("no url exists for this campaign!");
        }
    }

    if (saveNewCampaign) {
        var payload = {
            campaignName: newCampaignName,
            marketId: newCampaignMarketId,
            templateId: newCampaignTemplateId,
            domainId: newCampaignDomainId <= 0 ? null : newCampaignDomainId
        };

        return (<>
            <div>Saving new campaign...</div>
            <HttpFetcher params={{
                url: `api/campaign`,
                options: {
                    method: `POST`,
                    body: JSON.stringify(payload)
                },
                handleResponse: (json) => {
                    setSaveNewCampaign(false);
                    setNewCampaignName("");
                    setNewCampaignMarketId(0);
                    setNewCampaignTemplateId(0);
                    setNewCampaignDomainId(0);
                    setShowNewCampaign(false);
                    setDtos(null);
                }
            }}
            />
        </>);
    } else if (!markets) {
        return (<HttpFetcher params={{ url: 'api/market', handleResponse: (result) => handleGetMarkets(result) }} />);
    } else if (!templates) {
        return (<HttpFetcher params={{ url: 'api/template', handleResponse: (result) => handleGetTemplates(result) }} />);
    } else if (!domains) {
        return (<HttpFetcher params={{ url: 'api/domain', handleResponse: (result) => handleGetDomains(result) }} />);
    } else if (!dtos) {
        return (<HttpFetcher params={{ url: 'api/campaign', handleResponse: (result) => handleGetCampaigns(result) }} />);
    } else if (navigateTo) {
        return (<Navigate to={navigateTo} />);
    } else if (!dtos) {
        return (<div>Loading...</div>);
    } else if (dtos.length === 0) {
        return (<div>no campaigns</div>);
    } else {
        var newCampaign = null;

        if (showNewCampaign) {
            newCampaign = (
                <div className="campaigns-new container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <input type="text" onChange={handleNewCampaignNameChange} placeholder="New campaign name..." className={`form-control ${!newCampaignName ? 'invalid' : ''}`}></input>
                        </div>
                        <div className="col-md-2">
                            <select onChange={handleNewCampaignMarketChange} className={`form-control ${newCampaignMarketId === -1 ? 'invalid' : ''}`}>
                                <option defaultValue value="-1">--Market--</option>
                                {markets.map(market =>
                                    <option key={market.marketId} value={market.marketId}>
                                        {market.marketName}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select onChange={handleNewCampaignTemplateChange} className="form-control" className={`form-control ${newCampaignTemplateId === -1 ? 'invalid' : ''}`}>
                                <option defaultValue value="-1">--Template--</option>
                                {templates.map(template =>
                                    <option key={template.templateId} value={template.templateId}>
                                        {template.templateName}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="col-md-2">
                            <select onChange={handleNewCampaignDomainChange} className="form-control" className={`form-control ${newCampaignDomainId === -1 ? 'invalid' : ''}`}>
                                <option defaultValue value="-1">--Domain--</option>
                                <option defaultValue value="0">&lt;None&gt;</option>
                                {domains.map(domain =>
                                    <option key={domain.domainId} value={domain.domainId}>
                                        {domain.domainAddress}
                                    </option>
                                )}
                            </select>
                        </div>
                        <div className="col-md-2">
                            <button onClick={handleSaveNewCampaign} className="form-control">
                                Create
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <>
                <PageHeader>
                    <span className="primary">
                        Campaigns
                    </span>

                    <div className="secondary">
                        <button onClick={() => setShowNewCampaign(!showNewCampaign)}>
                            New Campaign
                        </button>
                    </div>
                </PageHeader>

                {newCampaign}

                <div className="table-responsive campaigns">
                    <table className="table table-striped" aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                <th className="first-cell">Name</th>
                                {Stages.getAll().map(stage => {
                                    if (stage.stageId != 0) {
                                        var cssClass = "stage-count-header";
                                        if (stage.stageId >= 98)
                                            cssClass += " unfocus";
                                        if (stage.stageId === 8)
                                            cssClass += " installed";
                                        return (
                                            <th key={stage.stageId} className={cssClass}>{stage.name}</th>
                                        )
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {dtos.map(dto =>
                                <tr key={dto.campaignId}>
                                    <td className="first-cell">
                                        <div className="campaign-name">
                                            {dto.campaignName} ({dto.leadCount})
                                            <span onClick={(e) => handleCampaignEdit(dto.campaignId)}><EditIcon /></span>
                                        </div>
                                        <div className="market-name">{dto.market.marketName}</div>
                                        <button className="add-lead" onClick={(e) => handleAddLead(dto.campaignId)}>Add Lead</button>
                                        <button className="add-lead" onClick={(e) => handleGetNewLeadUrl(dto.newLeadUrl)} style={{ 'display': dto.domain === null ? 'none' : 'block' }}>New Lead Url</button>
                                    </td>
                                    {dto.stageLeadCounts.map(count => {
                                        if (count.stageId != 0) {
                                            var cssClass = "stage-count-value";
                                            if (count.leadCount > 0)
                                                cssClass += "-exists";

                                            if (count.stageId >= 98)
                                                cssClass += " unfocus";

                                            if (count.stageId === 8)
                                                cssClass += " installed";

                                            return (
                                                <td key={count.stageId} onClick={(e) => count.leadCount > 0 ? handleNavigateTo(e, dto, count.stageId) : null} className={cssClass}>
                                                    {count.leadCount}
                                                </td>
                                            )
                                        }
                                    })}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </>
        );
    }
}